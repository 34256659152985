import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.article`
  & {
    .poster {
      img {
        object-fit: cover;
        width: 100%;
      }
    }
  }
`

export default function CardArticleEnAvant ({
  auteur,
  image,
  label,
  title,
  resume,
  link = '#',
  linkAccueil = ''
}) {
  return (
    <Wrapper className='mb-4'>
      <div className='mb-4'>
        <div className='poster'>
          <a href={link}>
            <img src={image} alt='' />
          </a>
        </div>
        <div className='font-poppins font-bold text-xl mb-1.5'>
          <a href={link} className='line-clamp-2'>
            {title}
          </a>
        </div>
        <div className='text-start text-base font-roboto font-normal text-color13 overflow-hidden line-clamp-3'>
          {resume}
        </div>
      </div>

      <div className='mb-4 text-end gap-4'>
        <div className='mb-2'>
          <a href={linkAccueil} className='label label-outline label-small'>
            {label}
          </a>
        </div>
      </div>
    </Wrapper>
  )
}
