import { Fragment, useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';


import Title2 from '../../Component/Title2'
import { HomeContext } from '../../Provider/HomeProvider'

export default function SectionEvenements () {
  const { evenements } = useContext(HomeContext)

  if (!evenements) {
    return false
  }

  console.log('evenements', evenements)

  return (
    <Fragment>
      <div className='max-w-screen-xl mx-auto mb-10'>
        <Title2>Les évènements à venir</Title2>
      </div>
      <div className='max-w-screen-xl mx-auto mb-10 static lg:flex gap-5 justify-items-stretch'>
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          centeredSlides={true}
          navigation
          pagination={{ clickable: true }}
          effect={'fade'}
          autoplay={{
            delay: 1000,           // Délai de 2,5 secondes entre chaque slide
            disableOnInteraction: false, // L'autoplay ne sera pas désactivé après une interaction
          }}
          modules={[Autoplay, EffectFade, Pagination, Navigation]}
          loop={true} 
        >
          {evenements.map((item, i) => (
            <SwiperSlide key={i} >
              <a href={item.href}>
                <img src={item.src} alt={item.alt} className='w-full rounded-xl' />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Fragment>
  )
}
