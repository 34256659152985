import React,{ useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import _ from 'lodash';
import Mp4Player from './Mp4Player';
import YoutubePlayer from './YoutubePlayer';

const validateYouTubeUrl = url => {
    if (url !== undefined || url !== '') {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        var match = url.match(regExp); 
        if (match && match[2].length === 11) {
            return match[2];
        }
        return false;
    }
}

const validateMp4Url = url => { 
    if (url !== undefined || url !== '') {
        let extension = _.last(url.split('.')).toLocaleLowerCase();
        return extension === 'mp4';
    }
    return false;
}

export default function Player({ onClose, video }) {

    const [ type, setType ] = useState(null);

    const [ url, setUrl ] =  useState(null);

    console.log('player', video);

    useEffect(() => {
        let _yt = validateYouTubeUrl(video.src);
        let _mp4 = validateMp4Url(video.src);
        
        if ( _yt !== false) {
            setType('video/youtube');
            setUrl(video.src);
        }

        if ( _mp4 !== false) { 
            setType('video/mp4');
            setUrl(video.src);
        }

    }, [video])

    return createPortal(
        <div className="video_player__wrapper">
            <button 
                className="video_player__close" 
                onClick={ onClose } 
                type="button" 
            >x</button>
            <div className="video_player__inner">
                { type === 'video/mp4' ? <Mp4Player src={url} /> : false }
                { type === 'video/youtube' ? <YoutubePlayer src={url} /> : false }
            </div>
        </div>,
        document.body
    )
}