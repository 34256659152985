import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.article`
  & {
    .poster {
        
        img {
            object-fit: cover;
            aspect-ratio: 60/80;
            width: 100%;
        }
    }
  }
`

export default function CardArticle ({
  auteur,
  image,
  label,
  title,
  resume,
  link = '#',
  linkAccueil = ''
}) {

  return (
    <Wrapper className='mb-4'>
      <div className='mb-4 grid grid-cols-3 gap-2'>
        <div className='basis-1/3 poster'>
            <a href={link}>
                <img src={image} alt='' />
            </a>
        </div>
        <div className='basis-2/3 col-span-2 pl-2'>
          <div className='font-poppins font-bold text-xl  mb-1.5'>
            <a href={link} className='line-clamp-2'>
              {title}
            </a>
          </div>
          <div className='text-start text-base font-roboto font-normal text-color13 overflow-hidden line-clamp-3'>
            {resume}
          </div>
        </div>
      </div>

      <div className='mb-4 flex text-end gap-4'>
        <div className='mb-2'>
          <a href={linkAccueil} className='label label-outline label-small'>
            {label}
          </a>
        </div>
      </div>
    </Wrapper>
  )
}
