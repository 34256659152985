import React, { Fragment, useState } from 'react';
import IconPlay from './../../assets/img/play.svg';
import Image from '../Image';
import Player from './Player';
import './index.css';

export default function VideoPlayer({ video, ratio, children }) {
    const [ show, setShow ] = useState(false);

    return (
        <Fragment>
            <div className="relative rounded-xl" onClick={ e => { setShow(true);  }}  >
                <Image ratio={ratio} src={video.image} alt=""  />
                {children}
                <div className="play-video" onClick={ e => setShow(true) } />
                <img className="absolute left-0 top-0 w-full h-full scale-50" src={IconPlay} alt="" />
            </div>
            <div className="play-video" onClick={ e => setShow(true) } />
            { show === true ? <Player video={ video }  onClose={ e => setShow(false) } /> : false }
        </Fragment>
    );
}