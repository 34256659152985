import axios from "axios";
import _ from 'lodash';

const PureMusicAdapater = (item) => {
    return {
        title: item.titre,
        album: item.album.titre,
        artiste: `${item.artiste.prenom} ${item.artiste.nom}`.trim(),
        src: item.source
    };
}

const VideoAdapter = (item) => {
    return {
        ...item,
        src: item.link,
        image: item.vignette
    }
}

export const getHomePureMusic = async () => {
    const response = await axios('https://www.pure-music.fr/mobile/json/topsemaine.php');

    return _.take(response.data, 5).map(item => PureMusicAdapater(item));
};

export const getVideosPureMusic = async () => {
    const response = await axios('https://www.pure-music.fr/api/videos/suggestions.php');
    return _.map(response.data, item => VideoAdapter(item));
};