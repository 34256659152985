import { Fragment, useContext } from 'react'
import _ from 'lodash'
import Title1 from '../../Component/Title1'
import Title2 from '../../Component/Title2'
import ButtonLinkLarge from '../../Component/ButtonLinkLarge'
import { HomeContext } from '../../Provider/HomeProvider'
import Image from '../../Component/Image'
import { AppContext } from '../../Provider/AppProvider'
import PlayerAudio from './../../assets/img/play.png'
import VideoPlayer from '../../Component/VideoPlayer'

export default function SectionPureMusic () {
  const { puremusic } = useContext(HomeContext)

  if (!puremusic) {
    return false
  }

  const _head = _.head(puremusic.videos)
  const _videos = _.slice(puremusic.videos, 1)

  return (
    <Fragment>
      <div className='max-w-screen-xl mx-auto mb-10 static lg:flex gap-5'>
        <div className='basis-2/3 flex flew-row justify-between items-center'>
          <div>
            <Title1>Pure Music</Title1>
            <Title2>Sélection vidéo de la semaine</Title2>
          </div>
          <div>
            <ButtonLinkLarge href='#' outline>
              Aller sur Pure Music
            </ButtonLinkLarge>
          </div>
        </div>
      </div>

      <div className='max-w-screen-xl mx-auto mb-10 static lg:flex gap-5'>
        <div className='basis-2/3'>
          <div className='mb-8'>
            <VideoPlayer ratio={1.5} video={_head}>
              <div className='absolute bg-gradient-to-b from-transparent to-black text-white bottom-0 left-0 right-0 w-full font-poppins font-bold text-2xl p-9 rounded-xl'>
                <h2>{_head.title}</h2>
                <p>{_head.artiste}</p>
              </div>
            </VideoPlayer>
          </div>

          <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mb-6'>
            {_videos.map((item, i) => (
              <div key={i} className='flex flex-col gap-2.5'>
                <VideoPlayer ratio={1.5} video={item} />
                <div className='font-poppins font-bold text-sm text-color7 line-clamp-2'>
                  {item.title} {!!item.artiste ? '' : `- ${item.artiste}`}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='md:basis-1/3 '>
          <div className='bg-color1 px-4 py-4 bg-color1 border border-solid border-color2 rounded-xl flex flex-col justify-around'>
            <h2 className='font-poppins text-xl font-bold text-center my-3 md:my-0'>
              PURE MUSIC
            </h2>
            <h2 className='font-poppins text-xl font-bold text-center my-3 mb-10 md:my-0'>
              le top de la semaine
            </h2>
            {puremusic.topSemaine.map((item, i) => (
              <div  key={i} className='mb-4'>
                <AudioRow {...item} number={i + 1} />
              </div>
              
            ))}
          </div>
        </div>
      </div>

      <div className='max-w-screen-xl mx-auto mb-10 '>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-y-6 gap-x-4 mb-8 text-sm font-poppins font-bold text-color7'>
          <div>
            <a href='https://www.pure-music.fr/#follow-your-mood'>
              <div className='mb-4'>
                <Image ratio={2} src='https://picsum.photos/id/102/400/198' />
              </div>
              <div className='font-poppins font-bold text-xl mb-1.5'>
                Adoration
              </div>
              <div className='text-start text-base font-roboto font-normal text-color13 overflow-hidden line-clamp-2'>
                Judy Jacobs, Graham Kendrick, Rich Gomez, Charisma, Phil
                Driscoll, William McDowell et plus…
              </div>
            </a>
          </div>
          <div>
            <a href='https://www.pure-music.fr/#follow-your-mood'>
              <div className='mb-4'>
                <Image ratio={2} src='https://picsum.photos/id/103/400/198' />
              </div>
              <div className='font-poppins font-bold text-xl mb-1.5'>
                Guerre Spirituelle
              </div>
              <div className='text-start text-base font-roboto font-normal text-color13 overflow-hidden line-clamp-2'>
                <div className='text-start text-base font-roboto font-normal text-color13 overflow-hidden line-clamp-2'>
                  Judy Jacobs, Graham Kendrick, Rich Gomez, Charisma, Phil
                  Driscoll, William McDowell et plus…
                </div>
              </div>
            </a>
          </div>
          <div>
            <a href='https://www.pure-music.fr/#follow-your-mood'>
              <div className='mb-4'>
                <Image ratio={2} src='https://picsum.photos/id/104/400/198' />
              </div>
              <div className='font-poppins font-bold text-xl mb-1.5'>
                Célébration
              </div>
              <div className='text-start text-base font-roboto font-normal text-color13 overflow-hidden line-clamp-2'>
                Rich Gomez, Paul Baloche, Mi Amigo Miguelito, Martha Munizzi,
                Charisma
              </div>
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const AudioRow = ({ title, artiste, album, src, number }) => {
  const { onPlay } = useContext(AppContext)

  return (
    <article>
      <div className='flex text-color7 font-poppins place-items-center'>
        <div className='flex-none text-4xl font-bold pl-4 pr-4'>{number}</div>
        <div className='grow text-base'>
          <p className='font-bold'>{title}</p>
          <p>
            {artiste} - {album}
          </p>
        </div>
        <div className='flex-none pr-4'>
          <img
            src={PlayerAudio}
            alt=''
            className='w-10'
            onClick={e => onPlay(src, title, artiste, album)}
          />
        </div>
      </div>
    </article>
  )
}
