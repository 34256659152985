import { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLocationPin,
  faEnvelope,
  faPhone
} from '@fortawesome/free-solid-svg-icons'

import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Logo2 from './../../assets/img/logo2.png'
import ButtonLinkLarge from '../../Component/ButtonLinkLarge'
import NewsletterForm from './NewsletterForm';

export default function Footer () {
  return (
    <Fragment>
      <div className='max-w-screen-xl mx-auto lg:flex gap-5 justify-items-stretch bg-color15 text-white p-14'>
        <div className='md:basis-1/4 mb-8'>
          <div className='font-poppins text-lg font-extrabold text-start flex gap-2.5 items-center mb-6'>
            <img className='float-left max-h-20' src={Logo2} alt='' />
            <h2>CHARISMA ÉGLISE CHRÉTIENNE</h2>
          </div>
          <p className='font-montserrat text-sm font-medium mb-6'>
            Plus qu’un endroit, une expérience.
          </p>
          <p className='font-montserrat text-xs font-medium mb-4'>
            <FontAwesomeIcon
              icon={faPhone}
              className='text-xs text-color5 mr-2'
            />
            01 48 14 97 77
          </p>
          <p className='font-montserrat text-xs font-medium mb-4'>
            <FontAwesomeIcon
              icon={faLocationPin}
              className='text-xs text-color5 mr-2'
            />
            7 Rue Isaac Newton, 93150 Le Blanc-Mesnil
          </p>
          <p className='font-montserrat text-xs font-medium mb-4 align-middle'>
            <FontAwesomeIcon
              icon={faEnvelope}
              className='text-xs text-color5 mr-2'
            />
            charisma@charisma.fr
          </p>
        </div>
        <div className='md:basis-3/4 mb-8'>
          <h2 className='text-2xl avenir-black'>
            L’ÉGLISE CHARISMA VOUS REMERCIE DE VOTRE VISITE !
          </h2>
          <p className='text-xl avenir-roman mt-2 mb-8'>
            Pour être tenu au courant de tous nos évènements, inscrivez-vous à
            notre newsletter !
          </p>
          <NewsletterForm />
          {/*
          <div className='flex flex-wrap gap-3 text-black mb-10'>
            <input
              type='text'
              placeholder='Nom et prénom'
              className='basis-1/3 rounded-[28px] px-4 py-2.5 text-color16'
            />
            <input
              type='email'
              placeholder='Adresse e-mail'
              className='basis-1/3 rounded-[28px] px-4 py-2.5 text-color16'
            />
            <ButtonLinkLarge href='#'>Inscrivez-vous</ButtonLinkLarge>
          </div>
           */}
          <div className='flex gap-3 text-black mb-6 flex-wrap md:justify-start justify-center'>
            <a
              href='mailto:lepasteur@charisma.fr'
              className='text-center text-sm text-color17 bg-white px-4 py-2.5 text-nowrap'
            >
              Envoyer un mail au <br />
              <strong>PASTEUR</strong>
            </a>
            <a
              href='mailto:secretariat@charisma.fr'
              className='text-center text-sm text-color17 bg-white px-4 py-2.5 text-nowrap'
            >
              Envoyer un mail au <br />
              <strong>SECRÉTARIAT</strong>
            </a>
            <a
              href='mailto:@'
              className='text-center text-sm text-color17 bg-white px-4 py-2.5 text-nowrap'
            >
              Envoyer un mail au <br />
              <strong>INFOS GF</strong>
            </a>
            <a
              href='mailto:editions@charisma.fr'
              className='text-center text-sm text-color17 bg-white px-4 py-2.5 text-nowrap'
            >
              Envoyer un mail au <br />
              <strong>EDITIONS CHARISMA</strong>
            </a>
            <a
              href='mailto:sospriere@charisma.fr'
              className='text-center text-sm text-color17 bg-white px-4 py-2.5 text-nowrap'
            >
              Envoyer un mail au <br />
              <strong>SOS PRIÈRE</strong>
            </a>
          </div>
        </div>
      </div>
      <div className='max-w-screen-xl mx-auto gap-5 justify-items-stretch bg-color15 text-white px-14 pb-14 text-center'>
        <p className='mb-8 avenir-roman text-xs'>
          Merci d’avoir visité le site de Charisma Église Chrétienne.
          <br />
          Copyright © 2014 Charisma Eglise Chrétienne. Tous droits réservés. |
          Termes | Politique de confidentialité
        </p>
        <p>
          <a href='https://www.instagram.com/eglise.charisma/?hl=fr'>
            <FontAwesomeIcon icon={faInstagram} className='text-xl mx-2' />
          </a>
          <a href='https://www.facebook.com/CharismaEgliseChretienne'>
            <FontAwesomeIcon icon={faFacebook} className='text-xl mx-2' />
          </a>
          <a href='https://twitter.com/eglisecharisma'>
            <FontAwesomeIcon icon={faTwitter} className='text-xl mx-2' />
          </a>
          <a href='https://www.youtube.com/@CHARISMATV1'>
            <FontAwesomeIcon icon={faYoutube} className='text-xl mx-2' />
          </a>
        </p>
      </div>
    </Fragment>
  )
}
