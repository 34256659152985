import { Fragment, useContext } from 'react'
import _ from 'lodash';
import { HomeContext } from './../../Provider/HomeProvider'
import Title1 from '../../Component/Title1'
import Title2 from '../../Component/Title2'
import ButtonLinkLarge from '../../Component/ButtonLinkLarge'
import ImageCard from '../../Component/ImageCard'
import VideoPlayer from '../../Component/VideoPlayer'

export default function SectionSpirituelle () {
  const { spirituelles, home } = useContext(HomeContext)

  if (!home & !spirituelles) {
    return false
  }

  const _head = _.last(home.videos)
  const _videos = _.slice(home.videos, 0, 6);

  return (
    <Fragment>
      <div
        id='spirituelle'
        className='max-w-screen-xl mx-auto mb-10 scroll-top'
      >
        <Title1>Nourrissez votre esprit</Title1>
        <Title2>Vidéos en streaming</Title2>
      </div>
      <div className='max-w-screen-xl mx-auto mb-10 static lg:flex gap-5'>
        <div className='basis-2/3'>
          <div className="mb-4">
            <VideoPlayer ratio={1.7}  video={{..._head, image: _head.photo, src: _head.link }} />
          </div>
          {/**
          <div className='max-w-screen-xl mx-auto my-10 flex flew-row justify-between items-center'>
            <div>
              <Title2>Les derniers cultes en ligne</Title2>
            </div>
            <div>
              <ButtonLinkLarge
                href='https://www.youtube.com/@CHARISMATV1'
                outline
              >
                Voir plus de cultes
              </ButtonLinkLarge>
            </div>
          </div>
           */}
          <div className='grid grid-cols-3 md:grid-cols-6 gap-4 mb-6'>
            {_videos.map((item, i) => (
              <div key={i} className='flex flex-col gap-2.5'>
                <VideoPlayer ratio={1.77}  video={{...item, image: item.photo, src: item.link }} />
                <div className='font-poppins font-bold text-sm text-color7 line-clamp-2'>{item.titre}</div>
              </div>
            ))}
          </div>
        </div>

        <div className='basis-1/3 bg-color1 border border-solid border-color2 rounded-xl px-4 py-4 flex flex-col justify-around'>
          <h2 className='font-poppins text-xl font-bold text-center text-color7 my-3 md:my-0'>
            Enseignements
          </h2>
          {spirituelles.enseignements.map((item, i) => (
            <article key={i}>
              <div className='grid grid-cols-2 gap-4 mb-3 text-sm font-poppins font-bold text-color7'>
                <div>
                  <ImageCard ratio={1.35} image={item.image} />
                </div>
                <div className='flex flex-col justify-start'>
                  <div className='mt-2 md:mt-4'>
                    <span className='label label-outline label-small'>
                      {item.theme}
                    </span>
                  </div>
                  <div className='mt-2 md:mt-4'>
                    <a href={item.link}>{item.title}</a>
                  </div>
                  <div className='date mt-2 md:mt-4'>{item.auteur}</div>
                </div>
              </div>
            </article>
          ))}
          <div className='text-center my-3 md:my-0'>
            <ButtonLinkLarge href='https://www.charisma.fr/fr/article2main.php'>
              Voir plus
            </ButtonLinkLarge>
          </div>
        </div>
      </div>
      <div className='max-w-screen-xl mx-auto mb-10'>
        <Title2>Bible en ligne</Title2>
      </div>

      <div className='max-w-screen-xl mx-auto mb-10 static lg:flex gap-5'>
        <div className='basis-2/3 relative'>
          <ImageCard ratio={2.45} image='/assets/img/home/bible_en_ligne.png' />
          <div
            className='absolute flex justify-center align-middle flex-col gap-4 '
            style={{ top: 0, bottom: 0, left: 0, width: '100%', right: '0' }}
          >
            <div className='flex justify-end md:place-items-end flex-col gap-4 content-between xs:p-2 md:p-20 '>
              <ButtonLinkLarge href='https://bible.charisma.fr/' className='md:w-80 min-w-20'>
                Lire la Bible en ligne
              </ButtonLinkLarge>
              <ButtonLinkLarge href='https://www.charisma.fr/fr/bible-audio.php' className='md:w-80 min-w-20' outline>
                Écouter la Bible en audio
              </ButtonLinkLarge>
            </div>
          </div>
        </div>

        <div className='basis-1/3 bg-color1 border border-solid border-color2 rounded-xl px-4 py-4 flex flex-col justify-around'>
          <h2 className='font-poppins text-xl font-bold text-center my-3 md:my-0 text-color7'>
            Verset du jour
          </h2>
          <div className='text-color13 text-base font-roboto text-center'>
            {spirituelles.versets.text}
          </div>
          <div className='text-color13 text-lg font-roboto font-bold text-center'>
            {spirituelles.versets.reference}
          </div>
        </div>
      </div>
      <div className='max-w-screen-xl mx-auto my-10 flex flew-row justify-between items-center'>
        <div>
          <Title2>Articles</Title2>
        </div>
        <div>
          <ButtonLinkLarge href='https://www.charisma.fr/fr/article2main.php' outline>
            Voir plus d’articles
          </ButtonLinkLarge>
        </div>
      </div>
      <div className='max-w-screen-xl mx-auto my-10 flex flew-row justify-between items-center'>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-y-6 gap-x-4 mb-8 text-sm font-poppins font-bold text-color7'>
          {spirituelles.articles.map((item, i) => (
            <article key={i} className='mb-4 flex flex-col'>
              <div className='mb-4'>
                <ImageCard ratio={1.5} image={item.image} />
              </div>
              <div className='mb-4'>
                <span className='label label-outline label-small label-auteur'>
                  {item.auteur}
                </span>
              </div>
              <div className='flex text-start gap-4'>
                <div className='mb-2'>
                  <span className='label label-outline label-small'>
                    {item.theme}
                  </span>
                </div>
                <div className='date'>{item.date}</div>
              </div>
              <div className='col-span-2 flex flex-auto font-poppins font-bold text-xl  mb-1.5'>
                <span className='line-clamp-2'>{item.title}</span>
              </div>
              <div className='text-start text-base font-roboto font-normal text-color13 overflow-hidden line-clamp-3'>
                {item.resume}
              </div>
            </article>
          ))}
        </div>
      </div>
    </Fragment>
  )
}
