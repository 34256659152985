import {createContext, useEffect, useState} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getHomePureMusic, getVideosPureMusic } from './../api/puremusic';
import { getHome, getArticleFlashnews, getArticles, getArticlesFlashnews, getThemes, getVideosHome, getEvenements, getRefonte } from '../api/charisma';
import { getVideos, getVideosYT } from '../api/video';

const page = {
    flashnews: {
        principale: 2932,
        articles: [2719, 2936, 2764, 3158],
        videos: [ 59, 935 ],
        populaires: [ 3157, 3156, 3155, 3154, 3153 ],
        actualites: [ 3143, 2679, 2597, 3154, 3119, 3132 ],
        recents: [ 2597, 2671, 3108 ],
        societes: [ 3069, 3121, 3120, 3057 ]
    },
    spirituelles: {
        enseignements: [ 2721, 3150, 1411, 10 ],
        versets: {
            text: "« Combats le bon combat de la foi, saisis la vie éternelle, à laquelle tu as été appelé, et pour laquelle tu as fait une belle confession en présence d’un grand nombre de témoins. »",
            reference: "1 Timothée 6:12"
        },
        articles: [ 2824, 2777, 6, 3005 ]
    }
};

export const HomeContext = createContext({
    puremusicTopSemaine: false,
    flashnews: false,
    spirituelles: false,
    puremusic: false
});

export const HomeProvider = (props) => {
    const [ flashnews, setFlashnews ] = useState(false);
    const [ puremusic, setPuremusic ] = useState(false);
    const [ spirituelles, setSpirituelles ] = useState(false);
    const [ temoignages, setTemoignages ] = useState(false);
    const [ evenements, setEvenements ] = useState(false);
    const [ vieEglise, setVieEglise ] = useState(false);
    const [ cetteSemaine, setCetteSemaine ] = useState(false);
    const [ home, setHome ] = useState(false);
    
    useEffect(() => {
        
        Promise.all(
            [
                getArticleFlashnews(page.flashnews.principale, 'flash'),
                getArticlesFlashnews(page.flashnews.articles, 'flash'),
                getArticlesFlashnews(page.flashnews.populaires, 'flash'),
                getVideos(page.flashnews.videos),
                getThemes(),
                getArticlesFlashnews(page.flashnews.actualites, 'flash'),
                getArticlesFlashnews(page.flashnews.recents, 'flash'),
                getArticlesFlashnews(page.flashnews.societes, 'flash'),
                getHome(),
                getVideosPureMusic()
        ]).then(
            values => {
                setFlashnews({
                    principale: values[0],
                    articles: values[1],
                    populaires: values[2],
                    videos: values[3],
                    themes: values[4],
                    actualites: values[5],
                    recents: values[6],
                    societes: values[7]
                })
                setHome(values[8])
            }
        );

        Promise.all([
            getArticles(page.spirituelles.enseignements, 'auteur'),
            getVideosYT(),
            getArticles(page.spirituelles.articles, 'auteur'),
        ]).then(values => setSpirituelles({
            enseignements: values[0],
            culte: values[1][0],
            youtubes: _.takeRight(values[1], 4),
            versets: page.spirituelles.versets,
            articles: values[2]
        }));

        Promise.all([ getVideosPureMusic(), getHomePureMusic() ]).then(values => setPuremusic({
            videos: values[0],
            topSemaine: values[1]
        }));

        getRefonte().then(value => {
            const { cettesemaine, temoignages, ve } = value;
 
            let articles = temoignages.articles;
            let themeHome = temoignages.themeHome;
            
            setTemoignages({
                alaunes: _.take(articles, 2),
                themeDuJour: {
                    label: themeHome.theme.nom,
                    articles: themeHome.articles
                },
                articles: articles
            });

            setVieEglise(ve);
            setCetteSemaine(cettesemaine);
        });
        
        getEvenements().then(value => setEvenements(value));
        

    }, [])

    useEffect(() => { console.log('home', home) }, [home]);

    return (
        <HomeContext.Provider
          value={{ cetteSemaine, evenements, flashnews, puremusic, spirituelles, temoignages, vieEglise, home }}
        >{props.children}</HomeContext.Provider>
    )
};

HomeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default HomeProvider;