import { Fragment } from 'react'
import Title1 from '../../Component/Title1'
import Title2 from '../../Component/Title2'
import ButtonLinkLarge from '../../Component/ButtonLinkLarge'

export default function SectionNotreEglise () {
  return (
    <Fragment>
      <div
        id='votre_eglise'
        className='max-w-screen-xl mx-auto mb-10 scroll-top'
      >
        <Title1>Votre église</Title1>
        <Title2>Ne manquez rien de ce qu’il s’y passe !</Title2>
      </div>
      <div className='max-w-screen-xl mx-auto mb-10 static lg:flex gap-5 justify-items-stretch'>
        <div className='basis-2/3'>
          <img src='/assets/img/home/bienvenue.png' alt='' />
        </div>
        <div className='basis-1/3 bg-color1 border border-solid border-color2 rounded-xl flex flex-col justify-around'>
          <div className='px-4 py-4'>
            <h2 className='font-poppins text-xl font-bold text-start my-4 text-color7'>
              Bienvenue à Charisma Église Chrétienne !
            </h2>
            <p className='font-poppins font-normal text-color14 text-base'>
              Charisma Église Chrétienne est devenue en l’espace de quelques
              années l’une des plus grandes congrégations de l’histoire du
              christianisme en France, avec plus de 12 000 personnes en
              communion. Venez découvrir notre église, son esprit et son cœur !
            </p>
            <div className='text-start my-4'>
              <ButtonLinkLarge href='https://www.charisma.fr/fr/pages.php?id=34'>Qui sommes-nous ?</ButtonLinkLarge>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
