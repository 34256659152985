import React, { useState } from 'react'
import axios from 'axios'
import ButtonLarge from '../../Component/ButtonLarge'

let _formInput = { nom: '', email: '' }

export default function NewsletterForm () {
  const [_form, setForm] = useState(_formInput)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const onSubmit = async event => {
    event.preventDefault()
    const _post = JSON.stringify(_form)
    try {
      const _res = await axios.post(
        'https://www.charisma.fr/fr/api/inscription.php',
        _post,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      setForm(_formInput)
      setIsSubmitted(true)
      console.log('_res', _res, _post)
    } catch (err) {
      console.error(err)
    }
    setIsSubmitted(true)
  }

  if (isSubmitted) {
    return <div className='flex flex-wrap gap-3 text-white mb-10 p-10 bg-green-400 rounded-lg text-xl'>
      <p>Votre inscription à la newsletter a été pris en compte</p>
    </div>
  } else {
    return (
      <form onSubmit={onSubmit}>
        <div className='flex flex-wrap gap-3 text-black mb-10'>
          <input
            type='text'
            placeholder='Nom et prénom'
            className='basis-1/3 rounded-[28px] px-4 py-2.5 text-color16'
            value={_form.nom}
            onChange={e => setForm({ ..._form, nom: e.target.value })}
            required
            name='inscription[nom]'
          />
          <input
            type='email'
            placeholder='Adresse e-mail'
            className='basis-1/3 rounded-[28px] px-4 py-2.5 text-color16'
            value={_form.email}
            onChange={e => setForm({ ..._form, email: e.target.value })}
            required
            name='inscription[email]'
          />
          <ButtonLarge type='submit'>Inscrivez-vous</ButtonLarge>
        </div>
      </form>
    )
  }
}
